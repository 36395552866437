:root {
  --light-grey: #bfbfbf;
  background-color: black;
}

#root {
  height: 100%;
  width: 100%;
  border: 1px solid var(--light-grey);
  overflow-y: scroll;
}
html,
body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: white !important;
  min-height: 100vh;
  min-width: 100wh;
  box-sizing: border-box;
  padding: 15px;
}

@keyframes gradient {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

@font-face {
  font-family: "Neue Montreal";
  src: url("fonts/NeueMontreal-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("fonts/NeueMontreal-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Neue Montreal";
  src: url("fonts/NeueMontreal-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

h1,
h2,
h3,
p,
.App {
  font-family: "Neue Montreal", "Times New Roman";
  color: white;
}

h1 {
  font-weight: 100;
  font-size: 32pt;
  letter-spacing: -2px;
  margin: 5px 0;
}

h2 {
  font-weight: 600;
  font-size: 12pt;
}

h3 {
  font-size: 12pt;
  font-weight: normal;
  margin: 0;
}

a,
li,
ul {
  color: inherit;
  list-style-type: square;
  color: white !important;
}

.splash-screen,
.splash-screen.fading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 1000;
  transition: opacity 1s ease, visibility 1s ease;
}

.splash-screen h1 {
  color: black !important;
}

.splash-screen {
  opacity: 1;
  visibility: visible;
}

.splash-screen.fading {
  opacity: 0;
  visibility: hidden;
}

.App {
  text-align: left;
  color: black;
  font-size: 11pt;
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  min-height: calc(100vh- 30px);
  min-width: calc(100wh - 30px);
  margin: 15px;
  padding: 15px;
  box-sizing: border-box;
}

.App-header,
.App-footer,
.App-main,
.App-sidebar {
  padding: 20px;
}

.App-footer {
  font-size: 10pt;
}

.App-body,
.App-main {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  margin: 0;
}

nav a {
  display: block;
  margin: 10px 0;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.App-sidebar ul {
  padding-left: 20px;
}

.gradient,
.image-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-thumbnail {
  width: 30%;
  height: auto;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.bulge-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  transition: transform 0.3s ease-in-out;
  border: 0.5px solid white;
}

.bulge-image:hover {
  transform: scale(1.1);
}

.headshot {
  filter: grayscale(100%);
  width: 175px;
  height: 275px;
  margin: 10px;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
}

.image-container,
.carousel-wrapper {
  position: relative;
  width: 175px;
  height: 225px;
}

.image-container img,
.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.centered-text {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-40%, -100%);
  color: rgb(248, 247, 247);
  font-size: 9pt;
  font-style: italic;
}

.parent-div {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.investor-deck-iframe {
  aspect-ratio: 1 / 0.618;
  position: relative;
  width: 50%;
}

/* Mobile Formatting*/
@media all and (max-width: 768px) {
  .App-body {
    flex-direction: column;
  }

  .App-main {
    padding: 10px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
  }

  .ul {
    padding: 0px;
  }

  .App-sidebar nav ul {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    margin: 0px;
  }

  .App-sidebar nav ul li {
    flex: 1;
    list-style-type: none;
    text-align: center;
    text-decoration: underline;
  }

  .App-sidebar {
    width: 100%;
    order: -1;
    padding: 0px;
    margin: 0px;
  }

  .image-grid {
    grid-template-columns: 1fr;
  }

  .portfolio-thumbnail {
    width: 100%;
    height: auto;
  }

  h1 {
    font-size: 26pt;
  }

  .investor-deck-iframe {
    aspect-ratio: 1 / 0.618;
    position: relative;
    width: 100%;
  }
}

.carousel {
  display: flex;
  overflow: scroll;
  width: 100%;
}

.carousel-slide {
  flex: 0 0 auto;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.icon-button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: white;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}
